const definitions = [
  {
    word: 'tuckpointing',
    definition:
      'Is a way of using two contrasting colours of motar in the motar joints of brickwork, one colour matching the bricks themselves, to give an artificial impression that very fine joints have been created',
  },
  {
    word: 're-pointing',
    definition:
      'Is the actual process of removing damaged joints and renewing them, this can be a Solid tuckpointing or Spot tuckpointing. This depends on the damage of the joints, due to excessive water damage or weather conditions.',
  },
  {
    word: 'parapet wall',
    definition:
      'Is a barrier which is an extension of the wall at the edge of a roof, terrace, balcony, walkway or other structure.',
  },
  {
    word: 'lintel',
    definition:
      'Is one type of beam which is used to support the above wall when there are openings like doors, windows, etc. they are necessary to provide a building structure. The main function is to take the loads coming from above wall and transfer its load to the side walls.',
  },
  {
    word: 'stucco',
    definition:
      'A weather -resistant mixture of dehydrated lime, powered marble and glue used in decorative mouldings on buildings also used for coating outside walls.',
  },
  {
    word: 'exterior insulation finish systems',
    definition:
      'Non-load bearing building cladding systems that provides exterior walls with an insulated, water-resistant, finished surface in an integrated composite material system.',
  },
  {
    word: 'caulking & sealant',
    definition:
      'Caulk is a waterproof filler and sealant, used in building work & repairs. Sealant is material used for sealing something so as to make it airtight or watertight.',
  },
  {
    word: 'efflorescence',
    definition:
      'It’s a crystalline deposit of salts often seen on the surface of concrete, brick, stucco or natural stone surfaces. It occurs when water leaves behind salt deposits and is present on or in the masonry surface.',
  },
  {
    word: 'flashing',
    definition:
      'Is a sheet of thin, impervious material used to prevent water penetration or seepage into a building and direct the flow of moisture in walls. There are 2 types exposed and embedded.',
  },
  {
    word: 'coping',
    definition:
      'Is the capping or covering of a parapet wall. In most cases the coping is pitched to allow the water to drain off the coping cap through the roof drainage system and not down the façade of the structure.',
  },
  {
    word: 'cmu',
    definition:
      'Concrete Masonry Unit-is a standard size rectangular block used in building construction. They are some of the most versatile building products available because of the wide variety of appearances that can be achieved using CMU’s(also known as cinder block)',
  },
  {
    word: 'bricks',
    definition:
      'A small rectangular block typically made of fired or sun-dried clay. Is used to make walls, pavements and other elements of masonry construction.',
  },
];

export default definitions;
