import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

const Schema = ({ content }) => {
  if (!content) return null

  return (
    <Helmet>
      <script type="application/ld+json">{content}</script>
    </Helmet>
  )
}

Schema.propTypes = {
  content: PropTypes.string,
}

export default Schema
