import terms from '../data/term-definitions';

export function addMarkupToContent(content) {
  let transformedContent = content;
  terms.forEach(term => {
    const word = term.word;
    const definition = term.definition;
    // const regex = new RegExp('(?<!data-tippy-content\=\"?.*)' + word, 'gi')
    const regex = new RegExp(word + '(?!.*?">)', 'i');

    transformedContent = transformedContent.replace(regex, match => {
      return `<span class="hasTooltip" data-tippy-content="${definition}">${match}</span>`;
    });
  });
  return transformedContent;
}
